class StoreSingleton {
  #store;

  get state() {
    return this.#store.getState();
  }

  init(store) {
    this.#store = store;
  }
}

export const storeSingleton = new StoreSingleton();
