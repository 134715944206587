import { loadableReady } from '@loadable/component';
import { fetchWithRetry, logTiming } from '@tunein/web-utils';
import { createBrowserHistory } from 'history';
import noop from 'lodash/noop';
import { hydrateRoot } from 'react-dom/client';
import { setHasAdBlocker } from 'src/common/actions/player';
import {
  checkIsAdBlockEnabled,
  disableAdBlockCheck,
} from 'src/common/components/utils/isAdBlockEnabled';
import { selectIsUnifiedEventsContentReportingEnabled } from 'src/common/selectors/config';
import { selectBreadcrumbId } from 'src/common/selectors/reporting';
import { isRootPathWithReporting } from 'src/common/utils/guideItemTypes';
import { logClientInfo } from '../common/actions/logging';
import { setBreadcrumbId, setPageLoadId } from '../common/actions/reporting';
import web from '../common/constants/analytics/categoryActionLabel/web';
import getRoutes from '../common/routes';
import {
  selectIsDiscord,
  selectIsFord,
  selectTuneInUserSerial,
} from '../common/selectors/app';
import { initBranch } from '../common/utils/branch/branchHelper';
import initDesktop from '../common/utils/desktop/initDesktop';
import { getActivityIdQueryParam } from '../common/utils/discord';
import removeElementById from '../common/utils/dom/removeElementById';
import { isDevBuild, isLocalEnv } from '../common/utils/environment';
import { updateReportingContext } from '../common/utils/reporting';
import Root from './Root';
import { initClientAnalytics, sendPageView } from './analytics';
import { apiSingleton, unifiedEvents } from './api';
import { DISCORD_DEVICE_SERIAL } from './constants/localStorage';
import configureClientStore from './store/configureClientStore';
import { storeSingleton } from './store/storeSingleton';
import createTuneInApiClient from './utils/createTuneInApiClient';
import localStorageController from './utils/localStorage';

function getIntialClientVars() {
  const { INITIAL_STATE: initialState, L10N } = window;

  delete window.INITIAL_STATE;
  delete window.L10N;
  removeElementById('initialStateEl');

  initialState.app.isDesktop = initDesktop(initialState.app.isDesktop);

  // HACK: storing/overriding the serial in Local Storage addresses a cookie issue in the Discord app on iOS.
  // Client-side cookie storage was also unreliable. See docs/features/Discord.md for more info.
  if (initialState.app.isDiscord) {
    const { app } = initialState;
    app.tuneInUserSerial = localStorageController.getOrSetDefault(
      DISCORD_DEVICE_SERIAL,
      app.tuneInUserSerial,
    );
  }

  return { initialState, L10N };
}

function registerWindowLoadListener(store) {
  window.onload = () => {
    // Using a setTimeout here so the browser may consider this `onload` callback as `completed`. By
    // pushing the callback to the event loop, this will simply allow us to measure metrics that can
    // only be measured after all `onload` callbacks are completed (e.g. measuring the loadEventEnd
    // metric, for which https://stackoverflow.com/a/11048634 was recommended).
    setTimeout(async () => {
      logTiming({ tuneInApiClient: apiSingleton.instance });
      unifiedEvents.reportPageLoadMetrics();

      const state = store.getState();

      if (selectIsDiscord(state)) {
        disableAdBlockCheck();
        return;
      }

      const isAdBlockEnabled = await checkIsAdBlockEnabled();
      const event = {
        category: web.category,
        action: web.actions.adblocker,
        label: isAdBlockEnabled,
      };

      apiSingleton.instance.logging.eventLog({ event }).catch(noop);
      store.dispatch(setHasAdBlocker(isAdBlockEnabled));
    }, 0);
  };
}

function setupDebugger(store, browserHistory) {
  if (!isLocalEnv()) {
    return;
  }

  window.tiDebug = {
    tuneInApiClient: apiSingleton.instance,
    store,
    browserHistory,
    toggleActionLogs() {
      const debugState = store.getState().app.debug;
      // we can mutate state here since this is for debugging purposes only
      debugState.showActionLogs = !debugState.showActionLogs;
    },
  };
}

function makeOnHistoryUpdate(store) {
  return (location) => {
    const storeState = store.getState();

    sendPageView(location);

    if (!selectIsUnifiedEventsContentReportingEnabled(storeState)) {
      return;
    }

    store.dispatch(setPageLoadId());

    if (
      isRootPathWithReporting(location.pathname) &&
      selectBreadcrumbId(storeState)
    ) {
      store.dispatch(setBreadcrumbId({ reset: true }));
    }
  };
}

function logAppSessionStarted(store, initialState) {
  const isDiscord = selectIsDiscord(initialState);

  unifiedEvents.reportApplicationSessionStarted();
  store.dispatch(
    logClientInfo({
      message: 'App session started',
      context: {
        ...(isDiscord && {
          state: { activityId: getActivityIdQueryParam() },
          durationMs: Math.round(
            performance.now() - window.discordLaunchTiming,
          ),
        }),
      },
    }),
  );

  if (isDiscord) {
    window.discordLaunchTiming = performance.now();
  }
}

function hydrateReactApp(store, L10N) {
  let numHotReloads = 0;

  function start() {
    hydrateRoot(
      document.getElementById('content'),
      <Root
        apiClient={apiSingleton.instance}
        onHistoryUpdate={makeOnHistoryUpdate(store)}
        store={store}
        routes={getRoutes()}
        numHotReloads={numHotReloads++}
        {...L10N}
      />,
    );
  }

  if (isDevBuild()) {
    start();

    if (module.hot) {
      // See for context on react-router + HMR:
      // - https://github.com/ReactTraining/react-router/issues/2182#issuecomment-147016657
      // - https://github.com/ReactTraining/react-router/issues/2182#issuecomment-170807654
      // This will be easier when react-router v4 drops https://github.com/ReactTraining/react-router/issues/2182#issuecomment-246901302
      module.hot.accept('../common/routes', start);
    }
  } else {
    // Instruction to wait for all bundles for the page to load before rendering
    // See https://github.com/gregberge/loadable-components
    // NOTE/TODO: depending on how other libraries should load based on page (e.g. @tunein/mint or
    // @tunein/web-tuner), this application may need to be broader.
    loadableReady(start);
  }
}

// This is a temporary hack for Ford mode to quickly favorite/unfavorite a random test station *without* the `username`
// query param to trick the Favorites API into accepting subsequent favorite calls with the `username` query param.
async function maybeExecuteFordDemoFavoritesHack(state) {
  const isFord = selectIsFord(state);

  if (!isFord) {
    return;
  }

  const serial = selectTuneInUserSerial(state);

  await fetchWithRetry(
    `https://opml.tunein.com/favorites.ashx?c=add&id=s324282&partnerId=oYbfCZPY&version=6.56&render=json&serial=${serial}`,
  ).catch(() => {});

  await fetchWithRetry(
    `https://opml.tunein.com/favorites.ashx?c=remove&id=s324282&partnerId=oYbfCZPY&version=6.56&render=json&serial=${serial}`,
  ).catch(() => {});
}

export default function initClient() {
  const { initialState, L10N } = getIntialClientVars();
  const tuneInApiClient = createTuneInApiClient(initialState);
  const browserHistory = createBrowserHistory();
  const store = configureClientStore(
    initialState,
    browserHistory,
    tuneInApiClient,
  );

  storeSingleton.init(store);
  apiSingleton.init(tuneInApiClient);
  updateReportingContext(store.getState());
  store.subscribe(() => updateReportingContext(store.getState()));
  maybeExecuteFordDemoFavoritesHack(initialState);
  logAppSessionStarted(store, initialState);
  store.dispatch(setPageLoadId());
  registerWindowLoadListener(store);
  setupDebugger(store, browserHistory);
  initClientAnalytics(store, browserHistory);
  initBranch(store, browserHistory.location.pathname);
  hydrateReactApp(store, L10N);
}
